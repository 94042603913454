import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getSandboxTokenAndLoginUser, exitApp } from '../../redux/actions/session';
import { PENDING, NO_AUTHENTICATED, IS_AUTHENTICATED, ADMIN } from '../../redux/reducers/session';
import getConfigValue from '../../config/enviromentVariables';
import JwtAuthService from '../../services/jwtService';
import CleverTap from '../../events';
import { PRODUCTIVE_MODE } from '../../common/authorizationModeType';
import Loading from './Loading/Loading';

class AuthGuard extends Component {
  state = {
    showContent: false,
    merchantInfoAndUserInfoLoaded: false,
  };

  componentDidMount() {
    this.authGuard();
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { status } = this.props;
    const { showContent } = this.state;
    return nextProps.status !== status || nextState.showContent !== showContent || nextProps.tokenSandbox !== null;
  }

  componentDidUpdate() {
    const { merchantInfoAndUserInfoLoaded } = this.state;
    const { status, userInfo, merchantInfo, tokenSandbox } = this.props;
    const canProcessAuth = status === IS_AUTHENTICATED && !!userInfo && !!merchantInfo && !!tokenSandbox;
    this.authGuard();
    if (canProcessAuth && !merchantInfoAndUserInfoLoaded) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ merchantInfoAndUserInfoLoaded: true });
      this.setState({ showContent: true });
    }
  }

  redirectRoute = () => {
    const token = JwtAuthService.getToken(PRODUCTIVE_MODE);
    const uri = token ? '' : '/users/sign_in?loginFrom=developerPortal';
    window.location.replace(`${getConfigValue('merchantDashboard')}${uri}`);
  };

  authGuard = () => {
    const { merchantInfo, userInfo, status, tokenSandbox, getSandboxTokenAndLoginUser: login } = this.props;
    if (status === NO_AUTHENTICATED || (userInfo && userInfo.role !== ADMIN)) {
      this.redirectRoute();
      return null;
    }
    if (status === PENDING && !userInfo && !merchantInfo && !tokenSandbox) {
      login();
    }

    if (status === IS_AUTHENTICATED && !!merchantInfo && !!userInfo && !!tokenSandbox) this.logProfile();

    return null;
  };

  logProfile = () => {
    const { userInfo } = this.props;
    CleverTap.profile(userInfo);
  };

  render() {
    const { showContent } = this.state;
    const { status, userInfo, merchantInfo, tokenSandbox, children } = this.props;
    const canProcessAuth = status === IS_AUTHENTICATED && !!userInfo && !!merchantInfo && !!tokenSandbox;
    return canProcessAuth && showContent ? <Fragment>{children}</Fragment> : <Loading />;
  }
}

AuthGuard.defaultProps = {
  merchantInfo: null,
  userInfo: null,
  status: 1,
  getSandboxTokenAndLoginUser: () => true,
  exitApp: () => true,
  children: null,
  tokenSandbox: null,
};

AuthGuard.propTypes = {
  merchantInfo: PropTypes.object,
  userInfo: PropTypes.object,
  status: PropTypes.number,
  tokenSandbox: PropTypes.string,
  getSandboxTokenAndLoginUser: PropTypes.func,
  exitApp: PropTypes.func,
  children: PropTypes.element,
};

const mapStateToProps = state => {
  const {
    session: { merchantInfo, userInfo, status, tokenSandbox },
  } = state;
  return { merchantInfo, userInfo, status, tokenSandbox };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ getSandboxTokenAndLoginUser, exitApp }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthGuard);
