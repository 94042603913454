import { rest } from 'msw';
import { makeFullUrl } from '../helpers/request';

export const webHooksHandlers = [
  rest.get(makeFullUrl('/api/merchant/settings', 1), (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        installment_body: null,
        notification_email: {
          enabled: 1,
          destination: 'miguel.martinezz@clipdashboard.com',
        },
        notification_postback: null,
      })
    );
  }),
  rest.post(makeFullUrl('/api/merchant/notification/postback/edit', 1), (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        notification: {
          postback: {
            enabled: 1,
            destination: 'www.someurl.com',
          },
        },
      })
    );
  }),
  rest.put(makeFullUrl('/api/merchant/notification/payment-postback-toggle', 1), (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        enabled: 1,
        endpoint: 'www.some.url',
      })
    );
  }),
  rest.get(makeFullUrl('/api/merchant/notification/postback-endpoint-health', 1), (req, res, ctx) => {
    return res(ctx.status(204));
  }),
];
