import clevertap from 'clevertap-web-sdk';
import getConfigValue from '../config/enviromentVariables';

const getFullEventName = eventName => {
  return `CX_DEV-DASHBOARD_${eventName}`;
};

export const EVENTS = {
  API_KEYS: {
    APP_LIST_VIEWED: 'API-KEYS_APP-LIST_VIEWED',
    APP_LIST_CREATE_NEW_CLICKED: 'API-KEYS_APP-LIST_CREATE-NEW-CLICKED',
    CREATE_APP_DIALOG: 'API-KEYS_CREATE-APP-DIALOG_DISPLAYED',
    CREATE_APP_SAVE: 'API-KEYS_CREATE-APP-DIALOG_SAVE-CLICKED',
    CREATE_APP_SUCCEEDED: 'API-KEYS_CREATE-APP_SUCCEEDED',
    CREATE_APP_FAILED: 'API-KEYS_CREATE-APP_FAILED',
    APP_DETAIL_CLICKED: 'API-KEYS_APP-LIST_APP-DETAIL-CLICKED',
    APP_DETAIL_DELETE_CLICK: 'API-KEYS_APP-DETAIL_DELETE-CLICKED',
    DELETE_APP_DIALOG_DISPLAYED: 'API-KEYS_DELETE-APP-DIALOG_DISPLAYED',
    DELETE_APP_CONFIRMATION_CLICKED: 'API-KEYS_DELETE-APP-DIALOG_CONFIRMATION-CLICKED',
    DELETE_APP_FAILED: 'API-KEYS_DELETE-APP_FAILED',
    DELETE_APP_SUCCEEDED: 'API-KEYS_DELETE-APP_SUCCEEDED',
  },
  WEBHOOKS: {
    SETUP_VIEWED: 'WEBHOOKS_SETUP_VIEWED',
    SETUP_URL_SUCCEEDED: 'WEBHOOKS_SETUP-URL_SUCCEEDED',
    SETUP_URL_FAILED: 'WEBHOOKS_SETUP-URL_FAILED',
    DISABLE_SUCCEEDED: 'WEBHOOKS_DISABLE_SUCCEEDED',
    DISABLE_FAILED: 'WEBHOOKS_DISABLE_FAILED',
    ENABLE_SUCCEEDED: 'WEBHOOKS_ENABLE_SUCCEEDED',
    ENABLE_FAILED: 'WEBHOOKS_ENABLE_FAILED',
    TEST_NOTIFICATION_SENT: 'WEBHOOKS_TEST-NOTIFICATION_SENT',
    TEST_NOTIFICATION_FAILED: 'WEBHOOKS_TEST-NOTIFICATION_FAILED',
  },
  SDK: {
    SDK_IOS_DOWNLOAD_VIEWED: 'SDK_IOS-DOWNLOAD_VIEWED',
    SDK_IOS_DOWNLOAD_CLICKED: 'SDK_IOS-DOWNLOAD_CLICKED',
    SAMPLE_PROJECTS_VIEWED: 'SDK_SAMPLE-PROJECTS_VIEWED',
    SAMPLE_DOWNLOAD_CLICKED: 'SDK_SAMPLE-DOWNLOAD_CLICKED',
  },
};

export const CleverTap = {
  initialize: function () {
    try {
      clevertap.privacy.push({ optOut: false });
      clevertap.privacy.push({ useIP: false });
      clevertap.spa = true;
      clevertap.init(getConfigValue('clevertapApiKey'));
    } catch (error) {
      console.log(error);
    }
  },

  event: function (name, payload) {
    if (process.env.NODE_ENV !== 'test') {
      try {
        if (payload) {
          clevertap.event.push(getFullEventName(name), payload);
          console.info('CLEVERTAP_EVENT', {
            name: getFullEventName(name),
            payload,
          });
        } else {
          clevertap.event.push(getFullEventName(name));
          console.info('CLEVERTAP_EVENT', {
            name: getFullEventName(name),
          });
        }
      } catch (error) {
        console.info('CLEVERTAP_EVENT', {
          name: getFullEventName(name),
          error: error,
        });
      }
    }
  },

  profile: function (userInfo) {
    clevertap.onUserLogin.push({
      Site: {
        Name: `${userInfo.first_name} ${userInfo.last_name} ${userInfo.second_last_name}`,
        Identity: userInfo?.email,
        Email: userInfo?.email,
        Phone: `+52${userInfo?.mobile}`,
        DOB: new Date(),
      },
    });
  },
};

export default CleverTap;
