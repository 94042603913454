import { createGetRequest, createPostRequest, createPutRequest } from '../../../helpers/reduxUtils';

export const POSTBACK_GET_INFO = 'POSTBACK_GET_INFO';
export const POSTBACK_UPDATE_WEBHOOK = 'POSTBACK_UPDATE_WEBHOOK';
export const POSTBACK_UPDATE_NOTIFICATION_STATUS = 'POSTBACK_UPDATE_NOTIFICATION_STATUS';
export const POSTBACK_TEST_WEBHOOK_HEALTH = 'POSTBACK_TEST_WEBHOOK_HEALTH';

export const getPostbackInfo = () => {
  const route = '/api/merchant/settings';
  return {
    type: createGetRequest(POSTBACK_GET_INFO),
    payload: {
      route,
    },
  };
};

export const updatePostbackWebHook = (endpoint, onSuccess, onFailure) => {
  const route = '/api/merchant/notification/postback/edit';
  const data = {
    endpoint: endpoint,
  };
  return {
    type: createPostRequest(POSTBACK_UPDATE_WEBHOOK),
    payload: {
      route,
      data,
      apiEndPoint: 1,
      success: onSuccess,
      failure: onFailure,
    },
  };
};

export const updatePostbackNotificationsStatus = (onSuccess, onFailure) => {
  const route = '/api/merchant/notification/payment-postback-toggle';
  return {
    type: createPutRequest(POSTBACK_UPDATE_NOTIFICATION_STATUS),
    payload: {
      route,
      data: {},
      apiEndPoint: 1,
      success: onSuccess,
      failure: onFailure,
    },
  };
};

export const testPostbackWebHook = (onSuccess, onFailure) => {
  const route = '/api/merchant/notification/postback-endpoint-health';
  return {
    type: createGetRequest(POSTBACK_TEST_WEBHOOK_HEALTH),
    payload: {
      route,
      data: {},
      apiEndPoint: 1,
      success: onSuccess,
      failure: onFailure,
    },
  };
};
