import React from 'react';
import Typography from '@clipmx/clip-storybook/dist/components/Typography';
import Icons from '../Icons';
import clsx from 'clsx';
import DrawerClip from '@clipmx/clip-storybook/dist/components/Drawer';
import ClipLogo from '@clipmx/clip-storybook/dist/components/Illustrations/ClipLogo';
import './AppSidebar.css';
import { useAppSidebar } from './useAppSidebar';

const AppSideBar = () => {
  const {
    props: { externalLinks, localLinks, openSideBar, isMobil },
    methods: { setOpenSideBar, removeSpaces },
    microcomponents: { ItemNavBar },
  } = useAppSidebar();

  return (
    <DrawerClip
      open={openSideBar}
      variant="permanent"
      anchor="left"
      className={clsx('sideBar', isMobil && 'mobil', openSideBar && 'openSideBar')}
    >
      <div className="sidebar-head">
        <ClipLogo color="orange" className={clsx('logo-clip', !openSideBar && 'hidden')} />
        <Icons
          type="icon"
          iconName={openSideBar ? 'menu_open' : 'menu'}
          className="icon-menu"
          onClick={() => setOpenSideBar(!openSideBar)}
        />
      </div>

      <aside className="sidebar-list" data-testid="sidebar">
        {localLinks.map(link => {
          return <ItemNavBar key={removeSpaces(link.name)} {...link} />;
        })}

        {openSideBar && <Typography className="subTitle">Otros</Typography>}

        {externalLinks.map(link => {
          return <ItemNavBar key={removeSpaces(link.name)} {...link} />;
        })}
      </aside>
    </DrawerClip>
  );
};

AppSideBar.defaultProps = {};

AppSideBar.propTypes = {};

export default AppSideBar;
