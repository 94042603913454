import React from 'react';
import './AppHeader.css';
import { useAppHeader } from './useAppHeader';
import ClipButton from '@clipmx/clip-storybook/dist/components/Button';
import clsx from 'clsx';

const AppHeader = () => {
  const {
    props: { showMenu, nameMerchant },
    methods: { setShowMenu, closeSession, getInitialsName },
  } = useAppHeader();
  return (
    <nav id="navbar">
      <div className="user-info">
        <div className="avatar-user">
          <span className="conatiner-name-merchant" onClick={() => setShowMenu(!showMenu)}>
            <span className="name">{nameMerchant}</span>
            <span className="letters">{getInitialsName()}</span>
          </span>
          <div className={clsx('options-menu', showMenu && 'show')}>
            <ClipButton onClick={closeSession}>Cerrar sesion</ClipButton>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default AppHeader;

AppHeader.defaultProps = {};
AppHeader.propTypes = {};
