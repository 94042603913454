import React, { memo, useContext } from 'react';
import { useDispatch } from 'react-redux';

import Typography from '@clipmx/clip-storybook/dist/components/Typography';
import Grid from '@clipmx/clip-storybook/dist/components/Grid';
import ClipButton from '@clipmx/clip-storybook/dist/components/Button';

import { testPostbackWebHook } from '../../../../redux/actions/webhooks';
import { AlertContext } from '../../Alert';

import getConfigValue from '../../../../config/enviromentVariables';

import useStyles from '../useStyles';
import { EVENTS, CleverTap } from '../../../../events';

const WebHookTest = memo(() => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { openAlert } = useContext(AlertContext);

  const handleClick = () => {
    dispatch(
      testPostbackWebHook(
        () => {
          CleverTap.event(EVENTS.WEBHOOKS.TEST_NOTIFICATION_SENT);
          openAlert({ severity: 'success', message: `La notificación de prueba se ha enviado exitosamente` });
        },
        () => {
          CleverTap.event(EVENTS.WEBHOOKS.TEST_NOTIFICATION_FAILED);
          openAlert({
            severity: 'error',
            message: 'Hubo un error al enviar la notificación de prueba. Comprueba tu URL y vuelve a intentarlo',
          });
        }
      )
    );
  };

  return (
    <div className={classes.webHookTestContainer} data-testid="webhook-test-container">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography fontWeight="bold" component="h2" variant="h6" color="inherit">
            Notificación de prueba
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography component="p" variant="body1" color="inherit">
            {`Envía una notificación de prueba a tu endpoint, el contenido de la respuestas
           se envía en formato JSON. Puedes saber más sobre los casos de notificación en la `}
            <a
              className={classes.webhookLink}
              target="_blank"
              rel="noopener noreferrer"
              href={getConfigValue('webHookSiteDoc')}
            >
              referencia técnica de Postback webhooks
            </a>
          </Typography>
        </Grid>
      </Grid>
      <Grid
        className={classes.webHookTestAction}
        spacing={2}
        container
        direction="row"
        justify="flex-end"
        alignItems="center"
      >
        <ClipButton data-testid="webhook-test-button" onClick={handleClick} variant="outlined" color="secondary">
          Enviar notificación
        </ClipButton>
      </Grid>
    </div>
  );
});

export default WebHookTest;
