const redTheme = theme => {
  const sidebarColor = '#fff';
  const sidebarBackground = '#fff';
  const sideBarWidth = 255;
  const sideBarCollapsedWidth = theme.spacing(7);
  return {
    width: sideBarWidth,
    widthCollapsed: sideBarCollapsedWidth,
    background: sidebarBackground,
    color: sidebarColor,
    sidebar: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      width: '100%',
      height: '100%',
      color: sidebarColor,
      background: sidebarBackground,
    },
    sidebarBackground: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      zIndex: 0,
      // backgroundImage: `url(${AppSidebarBg})`,
      backgroundImage: 'linear-gradient(to bottom, #fc4c02, #a8093c)',
      backgroundPosition: 'center',
      backgroundRepeat: 'repeat',
      backgroundSize: 'cover',
    },
    sidebarBody: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      width: '100%',
      height: '100%',
      overflowX: 'hidden',
      overflowY: 'auto',
    },
    sidebarHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-around',
      alignContent: 'center',
      whiteSpace: 'nowrap',
      padding: '0 8px',
      ...theme.mixins.toolbar,
    },
    sidebarSection: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'left',
      alignContent: 'center',
      whiteSpace: 'nowrap',
      padding: '0 16px',
      color: sidebarColor,
      ...theme.mixins.toolbar,
    },
    sectionTitle: {
      fontWeight: 400,
    },
    sidebarTitleLink: {
      textDecoration: 'none',
      color: 'inherit',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-around',
      alignContent: 'center',
      whiteSpace: 'nowrap',
    },
    logo: {
      color: theme.palette.secondary.main,
      zIndex: 10,
    },
    title: props => ({
      position: 'relative',
      overflow: 'visible',
      marginLeft: '5px',
      display: props.isCollapsed ? 'none' : 'block',
      fontSize: '1.1rem',
      letterSpacing: '.015em',
      // fontWeight: 'bold',
    }),
    name: {},
    tagline: {
      fontSize: 8,
      fontWeight: 'bold',
      position: 'absolute',
      top: '100%',
      marginTop: -5,
      background: theme.palette.primary.main,
      color: '#fff',
      borderRadius: 2,
      padding: '1px 3px',
      right: 0,
    },
    navList: {
      width: sideBarWidth,
      fontSize: '1.1em',
      fontWeight: 400,
      lineHeight: 1.5,
      letterSpacing: '0.00938em',
    },
    navListHeader: {
      textAlign: 'center',
      color: 'rgba(255,255,255,0.9)',
    },
    iconFeatures: {
      color: '#95de3c',
    },
    iconDocs: {
      color: '#f8cda9',
    },
    iconSupporters: {
      color: '#e3b546',
    },
    iconDiscuss: {
      color: '#ccc',
    },
    navItemWrapper: {
      position: 'relative',
    },
    navItemWrapperActive: {
      // background: 'rgba(0, 0, 0, 0.08)',
    },
    navItemWrapperActiveCollapsed: {
      background: 'rgba(0, 0, 0, 0.08)',
    },
    navItem: {
      position: 'relative',
      transition: 'background .23s ease',
      '&.active:not(.open)': {
        color: theme.palette.secondary.main,
        backgroundColor: 'white',
        // background: 'rgba(0, 0, 0, 0.08)',
        '& .MuiListItemIcon-root': {
          // color: '#fff',
          color: theme.palette.secondary.main,
        },
      },
      '&.open': {
        color: '#fff',
        '& .MuiListItemIcon-root': {
          color: '#fff',
        },
      },
    },
    navItemPoper: {
      width: sideBarWidth,
      color: 'rgba(0, 0, 0, 0.87)',
      background: sidebarBackground,
    },
    navItemChildren: {
      transition: 'background .23s ease',
      // position: 'absolute',
    },
    navItemChildrenActive: {
      background: 'rgba(0, 0, 0, 0.1)',
    },
    navItemCollapsed: {
      whiteSpace: 'nowrap',
      flexWrap: 'nowrap',
      width: sideBarCollapsedWidth,
      '& $iconToggle': {
        position: 'absolute',
        // bottom: -1,
        fontSize: 14,
        top: '50%',
        marginTop: '-0.5em',
        transform: 'rotate(90deg)',
        right: '3px',
      },
      '&.active': {
        background: 'rgba(0, 0, 0, 0.08)',
      },
    },
    navItemIcon: {
      minWidth: 40,
      color: 'white',
    },
    navItemIconCollapsed: {
      minWidth: 40,
      color: 'rgba(0, 0, 0, 0.87)',
    },
    iconToggle: {
      fontSize: '100',
    },
    iconToggleInactive: {
      opacity: 1,
    },
    iconSpacer: {
      fontSize: 13,
      marginLeft: 6,
    },
    navItemCollapsedWrapper: {
      width: sideBarCollapsedWidth,
    },
    menuDivider: {
      backgroundColor: 'rgba(255,255,255,0.2)',
    },
    logoSvg: props => ({
      display: 'inline-block',
      verticalAlign: 'text-bottom',
      width: props.size,
      height: props.size,
    }),
    path: {
      transition: 'all .3s ease',
    },
    outline: {
      fill: 'white',
    },
    letter: {
      fill: 'red',
    },
    backZone: {},
  };
};

export default redTheme;
