import React from 'react';
import './Credentials.css';
import { ClipTypography } from '@clipmx/clip-storybook/dist/components/Typography/Typography';
import Icons from '../Icons';
import { useCredentials } from './useCredentials';
import PropTypes from 'prop-types';
import getConfigValue from '../../../config/enviromentVariables';
import clsx from 'clsx';

const Credentials = ({ credentials, verifiedAccount }) => {
  const {
    props: { typeCredential, lengthCredentialsProd, lengthCredentialsTest },
    microcomponents: { ProdCredentialsList, TestCredentialsList, CtaCreate },
  } = useCredentials({ credentials });
  return (
    <div className="wrap-credentials" data-testid="wrap-credentials">
      <ClipTypography fontWeight="semiBold" variant="h2" component="h2" gutterBottom>
        Credenciales
      </ClipTypography>
      <ClipTypography variant="body1" align="justify" fontWeight="regular">
        Cada credencial proporciona un conjunto único de claves API y configuraciones para realizar solicitudes a las
        APIs de Clip.
      </ClipTypography>
      {verifiedAccount === false && (
        <div className={clsx('message', 'warning', 'verifiedAccount')}>
          <Icons type="icon" iconName="info" className="icon-info" />
          <p>
            <b>Importante:</b> Asegúrate de verificar tu identidad como se indica{' '}
            <a href={`${getConfigValue('devPortalDocSite')}reference/kyc`} target="_blank" rel="noopener noreferrer">
              aquí
            </a>{' '}
            antes de usar los productos de Clip (APIs | SDKs | Plugins). De lo contrario, tus clientes podrían tener
            rechazos en los pagos, lo que afectará el proceso de pagos y la experiencia de compra.
          </p>
        </div>
      )}
      <div className="container-categories">
        <div className="production" data-testid="container-prod-list">
          <ClipTypography className="title-prod" variant="h3" component="h3" fontWeight="semiBold" gutterBottom>
            <Icons type="icon" iconName="control_camera" className="icon-prod" />
            Producción
            <span className="length" data-testid="length-list">
              {lengthCredentialsProd}/6
            </span>
          </ClipTypography>
          <div className="region-information">{ProdCredentialsList}</div>
          <CtaCreate type={typeCredential.prod} />
        </div>
        <div className="test" data-testid="container-test-list">
          <ClipTypography className="title-test" variant="h3" component="h3" fontWeight="semiBold" gutterBottom>
            <Icons type="custom" nameCustomIcon="test" className="icon-test" />
            Pruebas
            <span className="length" data-testid="length-list">
              {lengthCredentialsTest}/6
            </span>
          </ClipTypography>

          <div className="region-information">{TestCredentialsList}</div>
          <ClipTypography className="message" variant="body1" fontWeight="regular" align="justify">
            <b>Importante:{` `}</b>
            Las credenciales de pruebas funcionan únicamente con el Checkout Transparente. Consulta la lista de APIs
            compatibles{' '}
            <a
              href={`${getConfigValue('devPortalDocSite')}reference/pruebas`}
              target="_blank"
              rel="noopener noreferrer"
            >
              aquí
            </a>
            .
          </ClipTypography>
          <CtaCreate type={typeCredential.test} />
        </div>
      </div>
    </div>
  );
};

Credentials.defaultProps = {
  credentials: {
    prodCredentials: null,
    testCredentials: null,
  },
};

Credentials.propTypes = {
  /**
   * The response from the server with
   * the merchant API keys information
   * This can be one of the two types
   * string for loading || object for the response
   */
  credentials: PropTypes.exact({
    prodCredentials: PropTypes.exact({
      items: PropTypes.array,
      messages: PropTypes.array,
      query: PropTypes.object,
      meta: PropTypes.object,
    }),
    testCredentials: PropTypes.exact({
      items: PropTypes.array,
      messages: PropTypes.array,
      query: PropTypes.object,
      meta: PropTypes.object,
    }),
  }),
};

export default Credentials;
