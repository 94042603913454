import React, { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';

import CardHeader from '@clipmx/clip-storybook/dist/components/Card/CardHeader';
import CardContent from '@clipmx/clip-storybook/dist/components/Card/CardContent';

import Card from '@clipmx/clip-storybook/dist/components/Card';
import Typography from '@clipmx/clip-storybook/dist/components/Typography';

import WebHookForm from '../forms/WebhookForm';
import WebHookToggle from './WebHookToggle';
import WebHookTest from './WebHookTest';

import useStyles from './useStyles';
import { EVENTS, CleverTap } from '../../../events';

const WebHookCard = memo(() => {
  const notificationStatus = useSelector(state => state?.webhooks?.enabled);
  const webHookUrl = useSelector(state => state?.webhooks?.destination);

  const showWebHookTest = !!notificationStatus && !!webHookUrl;
  const showWebHookToggle = !!webHookUrl;

  const classes = useStyles();

  useEffect(() => {
    const { WEBHOOKS } = EVENTS;
    CleverTap.event(WEBHOOKS.SETUP_VIEWED, {
      IS_ENABLED: showWebHookTest && showWebHookToggle,
    });
  }, [showWebHookTest, showWebHookToggle]);

  return (
    <Card className={classes.root} data-testid="webhook-card-container">
      <CardHeader
        title={
          <Typography
            className={classes.title}
            data-testid="webhook-card-title"
            component="h2"
            variant="h6"
            color="inherit"
          >
            Configuración de las notificaciones de pago por postback
          </Typography>
        }
      ></CardHeader>
      <CardContent>
        <Typography component="p" variant="body1" color="inherit" gutterBottom>
          Ingresa la URL del endpoint que recibirá las notificaciones.
        </Typography>
        <WebHookForm />
        {showWebHookToggle && <WebHookToggle />}
        {showWebHookTest && <WebHookTest />}
      </CardContent>
    </Card>
  );
});

export default WebHookCard;
