import { combineReducers } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';
import session from './session';
import webhooks from './webhooks';

export default combineReducers({
  session,
  webhooks,
  form: reduxFormReducer,
});
