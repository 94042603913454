import React, { createContext, useState, useContext, memo, useEffect } from 'react';

import ClipSnackbar from '@clipmx/clip-storybook/dist/components/Snackbar';
import ClipAlert from '@clipmx/clip-storybook/dist/components/Alert';
import './Alert.css';

export const AlertContext = createContext();

export const AlertProvider = ({ children }) => {
  const [alertValues, setAlertValues] = useState({
    severity: null,
    message: null,
  });

  return <AlertContext.Provider value={{ alertValues, openAlert: setAlertValues }}>{children}</AlertContext.Provider>;
};

/** Shows an alert in the top/center section of the app
 * This component is showed when one action (regularly actions) has
 * a response (success/error) and notifies the user about it.
 * @component
 * @name Alert
 */
export const Alert = memo(() => {
  const { alertValues } = useContext(AlertContext);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (alertValues?.severity) {
      setOpen(true);
    }
  }, [alertValues]);

  return (
    <div className="wrap-alert" data-testid="alert-component">
      {alertValues?.message && (
        <ClipSnackbar
          className="container-snackbar"
          anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
          open={open}
          autoHideDuration={3000}
          onClose={() => setOpen(false)}
        >
          <ClipAlert variant="clip" severity={alertValues?.severity} onClose={() => setOpen(false)}>
            {alertValues.message}
          </ClipAlert>
        </ClipSnackbar>
      )}
    </div>
  );
});
