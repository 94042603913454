import React, { useState } from 'react';
import { StylesProvider, createGenerateClassName } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import PropTypes from 'prop-types';
import { ClipThemeProvider, theme as clipTheme } from '@clipmx/clip-storybook';

const generateClassName = createGenerateClassName({
  seed: 'dp',
});

const ThemeProvider = props => {
  const { theme: rootTheme, component: Component, ...pageProps } = props;
  const overrides = { ...rootTheme.overrides, ...clipTheme.overrides };
  overrides.MuiAlert.icon.minWidth = 18;
  overrides.MuiAlert.icon.marginRight = 8;
  overrides.MuiAlert.message = {
    padding: 0,
    lineHeight: 'normal',
  };
  const mixedTheme = { ...rootTheme, overrides };
  const [theme, setTheme] = useState(mixedTheme);
  const [clippedHeader, setClippedHeader] = useState(false);
  const [withIcons, setWithIcons] = useState(false);
  const changeTheme = (name, data) => {
    const dataTheme = data(theme);
    const newTheme = { ...theme, [name]: { ...dataTheme } };
    setTheme(newTheme);
  };
  const changeHeaderType = () => {
    setClippedHeader(!clippedHeader);
  };
  const changeIconSettings = () => {
    setWithIcons(!withIcons);
  };
  return (
    <StylesProvider generateClassName={generateClassName}>
      <ClipThemeProvider theme={theme}>
        <CssBaseline />
        {Component && (
          <Component
            {...pageProps}
            clippedHeader={clippedHeader}
            changeHeaderType={changeHeaderType}
            changeTheme={changeTheme}
            withIcons={withIcons}
            changeIconSettings={changeIconSettings}
            theme={theme}
          />
        )}
        {!Component && <div>No component</div>}
      </ClipThemeProvider>
    </StylesProvider>
  );
};

ThemeProvider.defaultProps = {
  component: null,
  theme: {},
};

ThemeProvider.propTypes = {
  theme: PropTypes.object,
  component: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.element,
    PropTypes.func,
  ]),
};

export default ThemeProvider;
