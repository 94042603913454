import makeStyles from '@clipmx/clip-storybook/dist/theme/clipMakeStyles';

const useStyles = makeStyles(() => ({
  formContainer: {
    '& span': {
      marginTop: '-10px',
    },
    '& div > label': {
      paddingTop: '10px',
    },
  },
  inputContainer: {
    margin: '12px 0',
    '& input': {
      padding: '12px 4px',
    },
  },
  actions: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  edit: {
    cursor: 'pointer',
  },
}));

export default useStyles;
