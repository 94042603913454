import { createGetRequest, createPostRequest } from '../../../helpers/reduxUtils';
import { getMerchantInfo } from '../merchants';
import JwtAuthService from '../../../services/jwtService';

import cookieService from '../../../services/cookieService';
import { PRODUCTIVE_MODE, SANDBOX_MODE } from '../../../common/authorizationModeType';

export const LOGIN_WITH_TOKEN = 'LOGIN_WITH_TOKEN';
export const GET_SANDBOX_TOKEN = 'GET_SANDBOX_TOKEN';
export const LOGOUT = 'LOGOUT';

export function getSandboxTokenAndLoginUser() {
  return {
    type: createPostRequest(GET_SANDBOX_TOKEN),
    payload: {
      route: '/iam/sandbox/login',
      apiEndPoint: 2,
      success: (res, store) => {
        cookieService.saveToken(res?.data?.token, SANDBOX_MODE);
        store.dispatch(loginUserWithToken());
      },
    },
  };
}

export function loginUserWithToken() {
  const route = '/api/merchant/user/info';
  return {
    type: createGetRequest(LOGIN_WITH_TOKEN),
    payload: {
      route,
      success: (res, store) => {
        store.dispatch(getMerchantInfo());
      },
      mode: PRODUCTIVE_MODE,
    },
  };
}

export function exitApp() {
  return {
    type: LOGOUT,
  };
}

export function logout() {
  JwtAuthService.logout();
  return dispatch => {
    setTimeout(() => {
      dispatch(exitApp());
    }, 500);
  };
}
