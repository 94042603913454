/* eslint no-param-reassign: "off" */
/* eslint consistent-return: "off"  */
import produce from 'immer';
import { GET_SANDBOX_TOKEN, LOGIN_WITH_TOKEN, LOGOUT } from '../../actions/session';
import {
  MERCHANT_INFO,
  MERCHANT_API_KEY,
  MERCHANT_CREATE_API_APPLICATION,
  MERCHANT_DELETE_API_KEY,
  MERCHANT_VERIFIED_ACCOUNT,
} from '../../actions/merchants';
import { successResponse, errorResponse, requestState } from '../../../helpers/reduxUtils';
import { SANDBOX_MODE } from '../../../common/authorizationModeType';

export const PENDING = 1;
export const NO_AUTHENTICATED = 2;
export const IS_AUTHENTICATED = 3;

export const ADMIN = 'ADMIN';

export const initialState = {
  userInfo: null,
  merchantInfo: null,
  apiKey: null,
  apiKeySandBox: null,
  tokenSandbox: null,
  status: PENDING,
  isVerifiedAccount: null,
};

const session = (state = initialState, action = {}) =>
  produce(state, draft => {
    const { type, payload } = action;
    switch (type) {
      case successResponse('get', LOGIN_WITH_TOKEN): {
        draft.userInfo = payload.data;
        break;
      }
      case successResponse('get', MERCHANT_INFO): {
        draft.merchantInfo = payload.data;
        draft.status = IS_AUTHENTICATED;
        break;
      }
      case requestState('get', MERCHANT_API_KEY): {
        draft.apiKey = null;
        draft.apiKeySandBox = null;
        break;
      }
      case successResponse('post', GET_SANDBOX_TOKEN): {
        draft.tokenSandbox = payload?.data?.token;
        break;
      }
      case errorResponse('post', GET_SANDBOX_TOKEN): {
        draft.status = NO_AUTHENTICATED;
        break;
      }
      case requestState('post', MERCHANT_CREATE_API_APPLICATION): {
        break;
      }
      case successResponse('post', MERCHANT_CREATE_API_APPLICATION): {
        draft.apiKey = payload.data;
        break;
      }
      case successResponse('get', MERCHANT_API_KEY): {
        if (payload?.data?.query?.mode === SANDBOX_MODE) {
          draft.apiKeySandBox = payload.data;
        } else {
          draft.apiKey = payload.data;
        }
        break;
      }
      case errorResponse('post', MERCHANT_CREATE_API_APPLICATION):
        break;
      case errorResponse('get', MERCHANT_API_KEY): {
        draft.apiKey = null;
        draft.apiKeySandBox = null;
        break;
      }
      case successResponse('delete', MERCHANT_DELETE_API_KEY): {
        break;
      }
      case requestState('delete', MERCHANT_DELETE_API_KEY): {
        break;
      }
      case errorResponse('delete', MERCHANT_DELETE_API_KEY): {
        break;
      }
      case LOGOUT:
      case errorResponse('get', MERCHANT_INFO):
      case errorResponse('get', LOGIN_WITH_TOKEN): {
        draft.status = NO_AUTHENTICATED;
        draft.userInfo = initialState.userInfo;
        draft.merchantInfo = initialState.merchantInfo;
        draft.tokenSandbox = initialState.tokenSandbox;
        break;
      }
      case successResponse('get', MERCHANT_VERIFIED_ACCOUNT): {
        if (payload?.data?.KYC === 'KYC_ACCEPTED') {
          draft.isVerifiedAccount = true;
        } else {
          draft.isVerifiedAccount = false;
        }
        break;
      }
      case errorResponse('get', MERCHANT_VERIFIED_ACCOUNT): {
        draft.isVerifiedAccount = false;
        break;
      }
      default:
        break;
    }
  });

export default session;
