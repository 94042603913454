import React from 'react';
import { ClipInput } from '@clipmx/clip-storybook/dist/components/Input';

const InputLabeled = props => {
  const {
    input,
    id,
    inputProps,
    label,
    type,
    helperText,
    meta: { touched, error },
    enableTip,
    className,
    ...rest
  } = props;
  return (
    <ClipInput
      id={id}
      label={label}
      type={type}
      color="secondary"
      errormessage={error}
      error={error && touched}
      helperText={helperText}
      inputProps={{ ...inputProps }}
      fullWidth
      clickable={enableTip}
      popoverExternalFlag={enableTip}
      className={className}
      {...input}
      {...rest}
    />
  );
};

export default InputLabeled;
