import moment from 'moment-timezone';
import 'moment/locale/es';

// Formats date to spanish
// Languages other than English must be imported separately
moment.locale('es');

/**
 * Returns a date as short and full year (i.e Jan 2017)
 * @param date - date to be formatted
 * @return string - formatted date
 */
export function toMonthAndYear(date) {
  if (!date) return '';

  return moment(date).format('MMM YYYY').toUpperCase();
}

/**
 * Returns a full date in a short format (i.e 7 Jan 2017)
 * @param date - date to be formatted
 * @return string - formatted date
 */
export function toShortDate(date) {
  if (!date) return '';

  return moment(date).format('D MMM YYYY').toUpperCase();
}

/**
 * Returns a standard date
 * Without full month spelling (i.e 07 Jan 2017)
 * @param date - date to be formatted
 * @return string - formatted date
 */
export function toDate(date) {
  if (!date) return '';

  return moment(date).format('DD MMMM YYYY').toUpperCase().replace('.', '');
}

/**
 * Returns the date in long format
 * eje. 16 DE MAYO DE 2024
 * @param date - date to be formatted
 * @return string - formatted date
 */
export function toDateLong(date) {
  if (!date) return '';
  return moment(date).format('LL').toUpperCase().replace('.', '').toLowerCase();
}

/**
 * Returns a standard date with time
 * Without full month spelling (i.e 07 Jan 2017)
 * @param date - date to be formatted
 * @return string - formatted date
 */
export function toDateTime(date) {
  if (!date) return '';

  const dateTime = moment(date).format('hh:mm A');
  return `${moment(date).format('DD MMM YYYY').toUpperCase()} ${dateTime} CST`;
}

/**
 * Determines if a date is within a specified period
 * @param date - date to be evaluated
 * @param start - start date of time period
 * @param end - end date of time period
 * @return boolean - wether or not date is between given params
 */
export function isBetween(date, start, end) {
  if (!date || !start || !end) return false;
  // eslint-disable-next-line no-param-reassign
  date = moment(date).format('YYYY-MM-DD');
  // eslint-disable-next-line no-param-reassign
  start = moment(start).format('YYYY-MM-DD');
  // eslint-disable-next-line no-param-reassign
  end = moment(end).format('YYYY-MM-DD');

  return moment(date).isBetween(start, end, null, '[]');
}

/**
 * Determines if a date is the same or after a given limit
 * @param date - date to be evaluated
 * @param limit  - number of units of time to be subtracted
 * from current date to find the start date
 * @param level  - unit type to be subtracted from start date
 * options are 'day', 'month', or 'year'
 * @return boolean - whether or not date is same or after start date
 */
export function isSameOrAfter(date, limit, level) {
  if (!date || !limit || !level) return false;

  const limitDate = moment().subtract(limit, `${level}s`);

  return moment(date).isSameOrAfter(limitDate, `${level}`);
}

/**
 * Determines if a date is expired (date provided is before current date/time)
 * @param date - date to be evaluated (timestamp in seconds)
 * @return boolean - wether or not date is expired
 */
export function isTimestampExpired(theDate) {
  if (!theDate) return true;
  const date = moment(theDate * 1000); // seconds to milliseconds

  return moment().isSameOrAfter(date);
}

/**
 * Determines if a date is expired (date provided is before current date/time)
 * @param list - List of items to sort
 * @param nameProp - Name of the property that stores the date
 * @return array - List of elements ordered by the indicated property
 */
export function orderByDate(list = [], nameProp, type = 'asc' /* or  des */) {
  const isInvalidProp = list.some(item => {
    if (!item[nameProp]) {
      return true;
    }
    return false;
  });

  if (isInvalidProp) {
    return list;
  }

  return list.sort(function (a, b) {
    var c = new Date(a[nameProp].replace('Z', ''));
    var d = new Date(b[nameProp].replace('Z', ''));
    if (type === 'asc') {
      return c - d;
    }
    return d - c;
  });
}
