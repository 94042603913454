import JwtAuthService from '../services/jwtService';
import getConfigValue from '../config/enviromentVariables';
import { PRODUCTIVE_MODE } from '../common/authorizationModeType';

const axios = require('axios');

export const GET = 'get';
export const POST = 'post';
export const PUT = 'put';
export const PATCH = 'path';
export const DELETE = 'delete';

export const makeFullUrl = (url, apiEndPoint = 1) => {
  const apiBase = getConfigValue(`apiUrl${apiEndPoint}`);
  return `${apiBase || 'https://dev-api-gateway.payclip.com'}${url}`;
};

export const getRequestOptions = (mode = PRODUCTIVE_MODE) => {
  const token = JwtAuthService.getToken(mode);
  const headers = {
    Accept: 'application/vnd.com.payclip.v1+json',
  };

  if (token) {
    headers.Authorization = token;
  }

  return headers;
};

/**
 * Post data to our middleware server.
 */
export function apiSendData(
  method,
  url,
  params,
  data = null,
  mode = PRODUCTIVE_MODE,
  apiEndPoint = 1,
  fullRoute = false,
  headers = {}
) {
  const axiosConfig = {
    method,
    url: fullRoute ? url : makeFullUrl(url, apiEndPoint),
    data,
    params,
    headers: { ...getRequestOptions(mode), ...headers },
  };
  return new Promise((resolve, reject) => {
    axios(axiosConfig)
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
}
