import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './index.css';
import ThemeProvider from './components/common/ThemeProvider';
import theme from './theme';
import Router, { AppRouterComponent } from './routes/Router';
import store from './redux/storeConfig';
import AuthGuard from './components/common/AuthGuard';
import * as serviceWorker from './serviceWorker';
import CleverTap from './events/index';
import getConfigValue from './config/enviromentVariables';
import './vars.css';
import './App.css';

import './overwrite.css';

require('dotenv').config();

CleverTap.initialize();

if (getConfigValue('debugMSW') === 'MSW') {
  require('./mocks');
}

const App = () => {
  return (
    <AppRouterComponent>
      <AuthGuard>
        <Router />
      </AuthGuard>
    </AppRouterComponent>
  );
};
const theStore = store();

ReactDOM.render(
  <Provider store={theStore}>
    <ThemeProvider theme={theme} component={App} />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
