import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

import { DialogProvider } from '@clipmx/clip-storybook/dist/components/Dialog/dialogContext';
import { Alert, AlertProvider } from '../components/common/Alert';

const RouteWithLayout = ({ component: Component, layout: Layout, prevProps, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        if (Layout) {
          return (
            <AlertProvider>
              <DialogProvider>
                <Alert />
                <Layout {...props} {...prevProps}>
                  <Component {...props} {...prevProps} />
                </Layout>
              </DialogProvider>
            </AlertProvider>
          );
        }
        return <Component {...props} />;
      }}
    />
  );
};

RouteWithLayout.defaultProps = {
  component: null,
  layout: null,
  prevProps: null,
};

RouteWithLayout.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.element,
    PropTypes.func,
    PropTypes.object,
  ]),
  layout: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.element, PropTypes.func]),
  prevProps: PropTypes.object,
};

export default RouteWithLayout;
