import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { CleverTap, EVENTS } from '../../../events';
import { DialogContext } from '@clipmx/clip-storybook/dist/components/Dialog/dialogContext';
import { ClipTypography } from '@clipmx/clip-storybook/dist/components/Typography/Typography';
import Icons from '../Icons';
import EmptyCredentialsImg from './../../../files/empty-credentials.svg';
import Skeleton from '../Skeleton/Skeleton';
import NewCredential from '../forms/NewCredential/NewCredential';
import { orderByDate, toDateLong } from '../../../helpers/dateHelpers';
import { ClipButton } from '@clipmx/clip-storybook/dist/components/Button/Button';

export const useCredentials = ({ credentials: { prodCredentials = null, testCredentials = null } }) => {
  const history = useHistory();

  const { dialogActions } = useContext(DialogContext);

  const typeCredential = {
    test: 'test',
    prod: 'prod',
  };

  const createCredential = (credential = typeCredential.prod) => {
    CleverTap.event(EVENTS.API_KEYS.APP_LIST_CREATE_NEW_CLICKED, { SOURCE: 'APP_LIST' });
    dialogActions.registerDialog('ApplicationName', {
      open: true,
      type: 'form',
      maxWidth: 'xl',
      title: 'Crear credencial',
      children: <NewCredential typeCredential={credential} />,
      name: 'ApplicationName',
    });
  };

  const redirectToCredential = (
    nameCredential = '',
    apiKeyCredential = '',
    type = typeCredential.prod,
    createdAt = ''
  ) => {
    if (nameCredential !== '' && apiKeyCredential !== '') {
      CleverTap.event(EVENTS.API_KEYS.APP_DETAIL_CLICKED);
      history.push({
        pathname: `/credentials/${apiKeyCredential}`,
        state: { nameCredential, apiKeyCredential, typeCredential: type, createdAt },
      });
    }
  };

  const EmptyCredentials = ({ type = typeCredential.prod }) => (
    <div className="container-empty-data" data-testid={`container-empty-data-${type}`}>
      <img src={EmptyCredentialsImg} alt="Empty Credentials" />
      <ClipTypography className="message-empty" variant="body1" fontWeight="regular" gutterBottom>
        Aún no creas credenciales para pruebas.
      </ClipTypography>
    </div>
  );

  const RenderListByType = ({ credentials, type = typeCredential.prod }) => {
    if (credentials === null) {
      return <Skeleton length={3} height={'60px'} data-testid={`skeleton-${type}`} />;
    }

    const items = credentials?.items ?? [];
    if (items.length === 0) {
      return <EmptyCredentials type={type} />;
    }

    return (
      <>
        {Array.from(orderByDate([...items], 'created_at', 'des')).map((credential, index) => (
          <div
            key={`${type}-${index}`}
            className="container-link"
            data-testid={`credential-element-${type}`}
            onClick={() => redirectToCredential(credential.name, credential.api_key, type, credential.created_at)}
          >
            <Icons type="custom" nameCustomIcon="key" className="icon-credential" />
            <ClipTypography className="title-credential" variant="subtitle1" fontWeight="semiBold" gutterBottom>
              {credential.name}
              <p>{toDateLong(credential.created_at)}</p>
            </ClipTypography>
            <Icons type="icon" iconName="keyboard_arrow_right_rounded" className="icon-arrow" />
          </div>
        ))}
      </>
    );
  };

  return {
    props: {
      typeCredential,
      lengthCredentialsProd: prodCredentials?.items?.length ?? 0,
      lengthCredentialsTest: testCredentials?.items?.length ?? 0,
    },
    microcomponents: {
      ProdCredentialsList: <RenderListByType credentials={prodCredentials} type={typeCredential.prod} />,
      TestCredentialsList: <RenderListByType credentials={testCredentials} type={typeCredential.test} />,
      CtaCreate: ({ type = typeCredential.prod }) => {
        const credentialsList = type === typeCredential.prod ? prodCredentials : testCredentials;

        if (credentialsList === null) return <></>;

        const lengthList = credentialsList?.items?.length ?? 0;

        return (
          <>
            {lengthList < 6 ? (
              <ClipButton
                className="cta"
                variant="contained"
                color="primary"
                startIcon={<Icons type="icon" iconName="add" />}
                onClick={() => createCredential(type)}
                data-testid={`cta-create-${type}`}
              >
                Crear credencial
              </ClipButton>
            ) : (
              <ClipTypography
                className="message-max-length"
                variant="body1"
                fontWeight="regular"
                align="center"
                gutterBottom
                data-testid={`message-max-length-${type}`}
              >
                Has alcanzado el número máximo de credenciales permitidas
              </ClipTypography>
            )}
          </>
        );
      },
    },
  };
};
