/* eslint prefer-destructuring: "off" */

export function requestState(method = 'get', resource = 'RESOURCE') {
  return `API_${method.toUpperCase()}_${resource}_REQUEST`;
}

export function successResponse(method = 'get', resource = 'RESOURCE') {
  return `API_${method.toUpperCase()}_${resource}_SUCCESS`;
}

export function errorResponse(method = 'get', resource = 'RESOURCE') {
  return `API_${method.toUpperCase()}_${resource}_ERROR`;
}

export function createConstant(resource = 'RESOURCE', method = 'get', type = 'request') {
  let suffix = type.toLowerCase();
  let verb = method.toLowerCase();

  switch (suffix) {
    case 'request':
      suffix = 'REQUEST';
      break;
    case 'error':
      suffix = 'ERROR';
      break;
    case 'success':
      suffix = 'SUCCESS';
      break;
    default:
      suffix = 'REQUEST';
  }

  switch (verb) {
    case 'get':
      verb = 'GET';
      break;
    case 'post':
      verb = 'POST';
      break;
    case 'delete':
      verb = 'DELETE';
      break;
    case 'put':
      verb = 'PUT';
      break;
    case 'patch':
      verb = 'PATCH';
      break;
    default:
      verb = 'GET';
  }

  return `API_${verb}_${resource}_${suffix}`;
}

export function createGetRequest(resource) {
  return createConstant(resource, 'get');
}

export function createPostRequest(resource) {
  return createConstant(resource, 'post');
}

export function createPutRequest(resource) {
  return createConstant(resource, 'put');
}

export function createPatchRequest(resource) {
  return createConstant(resource, 'patch');
}

export function createDeleteRequest(resource) {
  return createConstant(resource, 'delete');
}

export function createResponse(actionInfo, response, type) {
  return {
    type: createConstant(actionInfo.resource, actionInfo.verb, type),
    payload: {
      code: response.status,
      data: response.data,
    },
  };
}

export function createSuccessResponse(actionInfo, response) {
  return createResponse(actionInfo, response, 'success');
}

export function createErrorResponse(actionInfo, response) {
  return createResponse(actionInfo, response, 'error');
}

export function getActionTypeInfo(actionType) {
  const info = actionType.split('_');
  const invalidResponse = { correctActionType: false };
  const validResponse = {
    prefix: '',
    verb: '',
    suffix: '',
    resource: '',
    correctActionType: '',
    responseActionType: '',
  };
  if (info.length < 4) {
    return invalidResponse;
  }
  validResponse.prefix = info[0];
  validResponse.verb = info[1];
  validResponse.suffix = info[info.length - 1];
  validResponse.resource = info.slice(2, info.length - 1).join('_');
  validResponse.correctActionType = validResponse.prefix === 'API' && validResponse.suffix === 'REQUEST';
  validResponse.responseActionType = `API_${validResponse.verb}_${validResponse.resource}`;
  return validResponse;
}

export function isObject(value) {
  return Object.prototype.toString.call(value) === '[object Object]';
}

export function isFunction(functionToCheck) {
  const getType = {};
  return functionToCheck && getType.toString.call(functionToCheck) === '[object Function]';
}

export function isString(value) {
  return typeof value === 'string' || value instanceof String;
}

export function isArray(value) {
  return value instanceof Array;
}
export function assingIdValue(paramObj, idName, objName) {
  const obj = paramObj;
  if (obj[objName] === undefined || obj[objName] === null) {
    obj[idName] = null;
    delete obj[objName];
    return obj;
  }
  obj[idName] = obj[objName].id;
  delete obj[objName];
  return obj;
}
