import React from 'react';
import PropTypes from 'prop-types';
import Button from '@clipmx/clip-storybook/dist/components/Button';
import { MenuOpenTwoTone as MenuOpenTwoToneIcon } from '@clipmx/clip-storybook/dist/components/Icons';
import { SwapHorizTwoTone as SwapHorizTwoToneIcon } from '@clipmx/clip-storybook/dist/components/Icons';
import redClipTheme from '../../themes/sideBar/redClip';

const AppConfiguration = props => {
  const { changeTheme, changeHeaderType, changeIconSettings } = props;
  return (
    <div style={{ margin: 0, width: '100%' }}>
      <div style={{ margin: '15px' }}>
        <Button
          variant="contained"
          style={{ marginRight: '30px' }}
          color="primary"
          startIcon={<MenuOpenTwoToneIcon />}
          onClick={() => changeTheme('sidebar', redClipTheme)}
        >
          Cambiar Tema Sidebar
        </Button>
        <Button
          variant="contained"
          style={{ marginRight: '30px' }}
          color="default"
          startIcon={<MenuOpenTwoToneIcon />}
          onClick={() => changeIconSettings()}
        >
          Cambiar modo iconos de Sidebar
        </Button>
        <Button
          startIcon={<SwapHorizTwoToneIcon />}
          variant="contained"
          color="secondary"
          onClick={() => changeHeaderType()}
        >
          Cambiar El tipo de Header
        </Button>
      </div>
    </div>
  );
};
AppConfiguration.defaultProps = {
  changeTheme: () => true,
  changeHeaderType: () => true,
  changeIconSettings: () => true,
};

AppConfiguration.propTypes = {
  changeTheme: PropTypes.func,
  changeHeaderType: PropTypes.func,
  changeIconSettings: PropTypes.func,
};

export default AppConfiguration;
