import { validateMode } from '../../../common/modeUtils';
import { createDeleteRequest, createGetRequest, createPostRequest } from '../../../helpers/reduxUtils';

export const MERCHANT_INFO = 'MERCHANT_INFO';
export const MERCHANT_API_KEY = 'MERCHANT_API_KEY';
export const MERCHANT_CREATE_API_APPLICATION = 'MERCHANT_CREATE_API_APPLICATION';
export const MERCHANT_DELETE_API_KEY = 'MERCHANT_DELETE_API_KEY';
export const MERCHANT_VERIFIED_ACCOUNT = 'MERCHANT_VERIFIED_ACCOUNT';

export function getMerchantInfo() {
  const route = '/api/merchant/info';
  return {
    type: createGetRequest(MERCHANT_INFO),
    payload: {
      route,
      success: (res, store) => {
        const headers = res?.config?.headers;
        if (Boolean(headers)) {
          store.dispatch(merchantVerifiedAccount(headers));
        }
      },
    },
  };
}

export function getMerchantApiKey(mode) {
  validateMode(mode);
  const route = '/apikey';
  return {
    type: createGetRequest(MERCHANT_API_KEY),
    payload: {
      route,
      apiEndPoint: 2,
      mode,
    },
  };
}

export function createApiApplication(name, onSuccess = () => {}, onFailure = () => {}, mode) {
  validateMode(mode);
  const route = '/apikey';
  const data = {
    type: 'BASIC',
    name,
  };
  return {
    type: createPostRequest(MERCHANT_CREATE_API_APPLICATION),
    payload: {
      route,
      data,
      apiEndPoint: 2,
      success: onSuccess,
      failure: onFailure,
      mode,
    },
  };
}

export function deleteApiApplication(apikeyId, onSuccess = () => {}, onFailure = () => {}, mode) {
  validateMode(mode);
  const route = `/apikey/${apikeyId}`;
  return {
    type: createDeleteRequest(MERCHANT_DELETE_API_KEY),
    payload: {
      route,
      apiEndPoint: 2,
      success: onSuccess,
      failure: onFailure,
      mode,
    },
  };
}

export function merchantVerifiedAccount(headers) {
  const route = `https://api-gw.payclip.com/kyc/fraud-profile`;
  return {
    type: createGetRequest(MERCHANT_VERIFIED_ACCOUNT),
    payload: {
      route,
      fullRoute: true,
      headers: headers,
    },
  };
}
