import axios from 'axios';
import jwtDecode from 'jwt-decode';
import cookieService from './cookieService';
import { isTimestampExpired } from '../helpers/dateHelpers';
import { PRODUCTIVE_MODE } from '../common/authorizationModeType';
import { validateMode } from '../common/modeUtils';

class JwtAuthService {
  constructor(storageService = cookieService) {
    this.storageService = storageService;
  }

  logout = () => {
    this.setSession(null);
    this.removeUser();
  };

  // Set Productive token to all http request header, so you don't need to attach everytime
  setSession = token => {
    // if (token && !this.isTokenExpired(token)) {
    if (token) {
      this.storageService.saveToken(token, PRODUCTIVE_MODE);
      axios.defaults.headers.common.Authorization = token;
    } else {
      this.storageService.clearToken();
      delete axios.defaults.headers.common.Authorization;
    }
  };

  isTokenExpired = token => {
    try {
      if (typeof token === 'undefined' || token === null || token === '') return true;
      const decoded = jwtDecode(token);
      return isTimestampExpired(decoded.exp);
    } catch (e) {
      console.log(e.message);
    }
    return true;
  };

  getToken = mode => {
    validateMode(mode);
    return this.storageService.getToken(mode);
  };

  // Save user to localstorage
  setUser = user => {
    this.storageService.setItem('auth_user', user);
  };

  // Remove user from localstorage
  removeUser = () => {
    this.storageService.removeItem('auth_user');
  };
}

const JwtAuthServiceInstance = new JwtAuthService();

export default JwtAuthServiceInstance;
