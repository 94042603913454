import React from 'react';
import { ClipTypography } from '@clipmx/clip-storybook/dist/components/Typography/Typography';
import { TextField } from '@material-ui/core';
import { ClipButton } from '@clipmx/clip-storybook/dist/components/Button/Button';
import CircularProgress from '@clipmx/clip-storybook/dist/components/CircularProgress';
import { bindActionCreators } from 'redux';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { createApiApplication } from '../../../../redux/actions/merchants';
import './NewCredential.css';
import { useNewCredential } from './useNewCredential';
import clsx from 'clsx';
import Icons from '../../Icons';

const NewCredential = ({ typeCredential = 'prod', ...otherProps }) => {
  const {
    props: { inputNameCredential, EVALUATIONS, RULES, sendRequest, isError, rulesKeysToRender },
    methods: { onChange, onCloseDialog, onClickCreate },
  } = useNewCredential({ ...otherProps, typeCredential });
  return (
    <form className="container-action-create-credential" data-testid={`form-new-credential-${typeCredential}`}>
      <div className="region-data">
        <ClipTypography variant="body1" align="justify" fontWeight="regular">
          Asigna un nombre único a tu nueva credencial de {typeCredential === 'prod' ? 'producción.' : 'pruebas.'}
        </ClipTypography>
        <TextField
          className="input-name-credetial"
          label="Nombre de credencial"
          variant="outlined"
          value={inputNameCredential.value}
          error={isError}
          helperText={
            inputNameCredential.error !== '' ? inputNameCredential.error : 'Cada credencial debe llamarse distinto.'
          }
          onChange={onChange}
          InputProps={{
            endAdornment: isError ? <Icons type="icon" iconName="info_outlined" className="icon-error" /> : null,
          }}
          inputProps={{
            'data-testid': 'input-name-credential',
          }}
          FormHelperTextProps={{
            'data-testid': isError ? 'error-message' : 'indication-message',
          }}
        />
        <ul className="rules" data-testid="rules-list">
          {rulesKeysToRender.map(key => {
            return (
              <li
                key={`rule-${key}`}
                data-testid={`rule-${key}`}
                className={clsx('item-rule', EVALUATIONS[key] && 'success')}
              >
                {RULES[key]?.text}
              </li>
            );
          })}
        </ul>
      </div>
      <div className="region-actions">
        <span className="cancelar" onClick={onCloseDialog}>
          Cancelar
        </span>
        <ClipButton
          className="create"
          variant="contained"
          color="primary"
          onClick={onClickCreate}
          disabled={sendRequest}
          data-testid="cta-create-credential"
        >
          {sendRequest ? <CircularProgress color="secundary" size={20} className="circular-progress" /> : 'Crear'}
        </ClipButton>
      </div>
    </form>
  );
};

const mapStateToProps = state => {
  const {
    session: { apiKey },
  } = state;
  return { apiKey };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ createApiApplication }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'createApiKeyForm', // a unique identifier for this form
  })(NewCredential)
);
