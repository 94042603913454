const sidebarColor = '#192E3A';
const sideBarWidth = 255;
export const sharedStyles = theme => ({
  sidebarHeader: props => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    alignContent: 'center',
    whiteSpace: 'nowrap',
    padding: props.isCollapsed ? '0 8px' : '0 16px',
    backgroundColor: 'white',
    ...theme.mixins.toolbar,
  }),
  headerLogo: {
    width: sideBarWidth,
  },
  headerLogoMobile: {
    width: 60,
    overflow: 'hidden',
  },
  sidebarHeaderCollapsed: {
    padding: '0 8px',
  },
  sidebarHeaderNone: {
    width: 0,
    height: 0,
    opacity: 0,
  },
  sidebarTitleLink: {
    textDecoration: 'none',
    color: 'inherit',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    alignContent: 'center',
    whiteSpace: 'nowrap',
  },
  logo: {
    color: theme.palette.secondary.main,
    zIndex: 10,
  },
  title: props => ({
    position: 'relative',
    overflow: 'visible',
    marginLeft: '10px',
    display: props.isCollapsed ? 'none' : 'block',
    fontSize: '1rem',
    letterSpacing: '.015em',
    // fontWeight: 'bold',
  }),
  name: {
    textTransform: 'uppercase',
    color: sidebarColor,
  },
});

const sidebarTheme = theme => {
  const sidebarBackground = '#fff';
  const headerColor = 'rgba(25,46,58,0.9)';
  const iconColor = sidebarColor;
  const sideBarCollapsedWidth = theme.spacing(7);
  return {
    width: sideBarWidth,
    widthCollapsed: sideBarCollapsedWidth,
    background: sidebarBackground,
    color: sidebarColor,
    sidebar: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      width: '100%',
      height: '100%',
      color: sidebarColor,
      background: sidebarBackground,
    },
    sidebarClipped: {
      ...theme.mixins.toolbar,
    },
    sidebarBackground: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      zIndex: 0,
      backgroundColor: '#FAFBFB',
    },
    sidebarBody: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      width: '100%',
      height: '100%',
      overflowX: 'hidden',
      overflowY: 'auto',
    },
    sidebarSection: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'left',
      alignContent: 'center',
      whiteSpace: 'nowrap',
      padding: '0 16px',
      color: '#1a2e3a',
      ...theme.mixins.toolbar,
    },
    sectionTitle: {
      fontWeight: 500,
    },
    tagline: {
      fontSize: 8,
      fontWeight: 'bold',
      position: 'absolute',
      top: '100%',
      marginTop: -5,
      background: theme.palette.primary.main,
      color: '#fff',
      borderRadius: 2,
      padding: '1px 3px',
      right: 0,
    },
    navList: {
      width: sideBarWidth,
      fontSize: '1.1em',
      fontWeight: 400,
      lineHeight: 1.5,
      letterSpacing: '0.00938em',
    },
    navListHeader: {
      textAlign: 'center',
      color: headerColor,
    },
    iconFeatures: {
      color: '#95de3c',
    },
    iconDocs: {
      color: '#f8cda9',
    },
    iconSupporters: {
      color: '#e3b546',
    },
    iconDiscuss: {
      color: '#ccc',
    },
    navItemWrapper: {
      position: 'relative',
    },
    navItemWrapperActive: {
      // background: 'rgba(0, 0, 0, 0.08)',
    },
    navItemWrapperActiveCollapsed: {
      background: 'rgba(0, 0, 0, 0.08)',
    },
    navItem: {
      position: 'relative',
      transition: 'background .23s ease',
      '&.active:not(.open)': {
        color: theme.palette.secondary.main,
        fontWeight: 500,
        // background: 'rgba(0, 0, 0, 0.08)',
        '& .MuiListItemIcon-root': {
          // color: '#fff',
          color: theme.palette.primary.main,
        },
      },
      '&.open': {
        fontWeight: 500,
        // color: `${Color(sidebarColor).alpha(0.8)}`,
        // backgroundColor: `${Color(theme.palette.primary.main).alpha(0.1).lighten(0.5)}`,
        '& .MuiListItemIcon-root': {
          color: sidebarColor,
        },
      },
      '&:hover': {
        color: sidebarColor,
        '& .MuiListItemIcon-root': {
          color: sidebarColor,
        },
      },
    },
    navItemPoper: {
      width: sideBarWidth,
      color: sidebarColor,
      background: sidebarBackground,
    },
    navItemChildren: {
      transition: 'background .23s ease',
      // position: 'absolute',
    },
    navItemChildrenActive: {
      background: 'rgba(0, 0, 0, 0.1)',
    },
    navItemCollapsed: {
      whiteSpace: 'nowrap',
      flexWrap: 'nowrap',
      width: sideBarCollapsedWidth,
      '& $iconToggle': {
        position: 'absolute',
        // bottom: -1,
        fontSize: 14,
        top: '50%',
        marginTop: '-0.5em',
        transform: 'rotate(90deg)',
        right: '3px',
      },
      '&.active': {
        background: 'rgba(0, 0, 0, 0.08)',
      },
    },
    navItemIcon: {
      minWidth: 40,
      color: `${iconColor}`,
    },
    navItemIconCollapsed: {
      minWidth: 40,
      color: 'rgba(0, 0, 0, 0.87)',
    },
    iconToggle: {
      fontSize: '1rem',
    },
    iconToggleInactive: {
      opacity: 1,
    },
    iconSpacer: {
      fontSize: 13,
      marginLeft: 6,
    },
    navItemCollapsedWrapper: {
      width: sideBarCollapsedWidth,
    },
    menuDivider: {
      backgroundColor: 'rgba(0,0,0,0.1)',
    },
    logoSvg: props => ({
      display: 'inline-block',
      verticalAlign: 'text-bottom',
      width: props.size,
      height: props.size,
    }),
    path: {
      transition: 'all .3s ease',
    },
    outline: {
      fill: '#FC4B00',
    },
    letter: {
      fill: sidebarBackground,
    },
    backZone: {},
    iconText: {
      fontWeight: 'bold',
      fontSize: 14,
      marginLeft: 7,
    },
    ...sharedStyles(theme),
  };
};

export default sidebarTheme;
