import React from 'react';
import PropTypes from 'prop-types';
import AppSidebar from '../../common/AppSidebar';
import AppHeader from '../../common/AppHeader/AppHeader';
import './DashboardLayout.css';

const DashboardLayout = ({ children }) => {
  return (
    <div className="container-dashboard-layout">
      <AppSidebar />
      <div className="conatiner-principal">
        <AppHeader />
        <main>{children}</main>
      </div>
    </div>
  );
};

DashboardLayout.defaultProps = {
  children: null,
};

DashboardLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default DashboardLayout;
