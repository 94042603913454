import { createMuiTheme } from '@material-ui/core/styles';

const themeService = () => {
  let theme = {};
  const configureThemes = (mainTheme = {}, cssClasses = {}) => {
    theme = createMuiTheme(mainTheme);
    Object.keys(cssClasses).forEach(key => {
      theme[key] = cssClasses[key](theme);
    });
    return theme;
  };
  const getTheme = () => {
    return theme;
  };
  return {
    configureThemes,
    getTheme,
  };
};

export default themeService();
