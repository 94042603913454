import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { logout } from '../../../redux/actions/session';
import getConfigValue from '../../../config/enviromentVariables';

export const useAppHeader = () => {
  const merchantInfo = useSelector(state => state.session.merchantInfo);
  const nameMerchant = merchantInfo?.merchant?.name;
  const [showMenu, setShowMenu] = useState();

  const addShadow = () => {
    const navbar = document.getElementById(`navbar`);
    const exitsClass = navbar?.classList.contains(`shadow`);
    if (window.scrollY >= 15) {
      if (!exitsClass) {
        navbar?.classList.add(`shadow`);
      }
    } else {
      if (exitsClass) {
        navbar?.classList.remove(`shadow`);
      }
    }
  };

  useEffect(() => {
    window.addEventListener(`scroll`, addShadow);
    return () => {
      window.removeEventListener(`scroll`, addShadow);
    };
  }, []);

  return {
    props: {
      showMenu,
      nameMerchant,
    },
    methods: {
      setShowMenu,
      closeSession: () => {
        logout();
        setShowMenu(false);
        window.location.replace(`${getConfigValue('merchantDashboard')}/users/sign_in?loginFrom=developerPortal`);
      },
      getInitialsName: () => {
        return nameMerchant.trim().split(/\s+/).length > 1
          ? `${nameMerchant.trim().split(/\s+/)[0][0].toUpperCase()}${nameMerchant
              .trim()
              .split(/\s+/)[1][0]
              .toUpperCase()}`
          : nameMerchant.trim().slice(0, 2).toUpperCase();
      },
    },
  };
};
