import React, { memo, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Typography from '@clipmx/clip-storybook/dist/components/Typography';
import ClipSwitch from '@clipmx/clip-storybook/dist/components/Switch';

import { updatePostbackNotificationsStatus } from '../../../../redux/actions/webhooks';
import { AlertContext } from '../../Alert';

import useStyles from '../useStyles';
import { EVENTS, CleverTap } from '../../../../events';

const WebHookToggle = memo(() => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const toggleStatus = useSelector(state => state?.webhooks?.enabled);
  const { openAlert } = useContext(AlertContext);
  const { ENABLE_SUCCEEDED, ENABLE_FAILED, DISABLE_SUCCEEDED, DISABLE_FAILED } = EVENTS.WEBHOOKS;

  const handleStatus = () => {
    dispatch(
      updatePostbackNotificationsStatus(
        () => {
          CleverTap.event(!!toggleStatus ? DISABLE_SUCCEEDED : ENABLE_SUCCEEDED);
          openAlert({ severity: 'success', message: `${getSuccessMessage()}` });
        },
        () => {
          CleverTap.event(!!toggleStatus ? DISABLE_FAILED : ENABLE_FAILED);
          openAlert({ severity: 'error', message: 'Se produjo un error. Intenta de nuevo.' });
        }
      )
    );
  };

  const getSuccessMessage = () => {
    return `Las notificaciones se han ${!!toggleStatus ? 'desabilitado' : 'habilitado'} exitosamente`;
  };

  return (
    <div className={classes.toggleContainer} data-testid="webhook-toggle-container">
      <Typography fontWeight="bold" variant="body1" className={classes.toggleDescription}>
        Activar recepción de notificaciones
      </Typography>
      <ClipSwitch
        data-testid="webhook-toggle"
        checked={!!toggleStatus}
        onChange={handleStatus}
        name="webhook-checked"
        inputProps={{ 'aria-label': 'secondary checkbox' }}
      />
    </div>
  );
});

export default WebHookToggle;
