import { theme as clipTheme } from '@clipmx/clip-storybook';

const rootTheme = {
  ...clipTheme,
  props: {
    MuiPaper: {
      elevation: 0,
    },
    MuiAppBar: {
      elevation: 1,
    },
    MuiMenu: {
      elevation: 1,
    },
    MuiCard: {
      elevation: 0,
    },
  },
  sharedStyles: {
    sectionHeader: {
      fontSize: 24,
      fontWeight: 600,
      marginBottom: 24,
    },
  },
  overrides: {
    MuiInputBase: {
      root: {
        '&.Mui-disabled': {
          opacity: '1 !important',
        },
      },
    },
    MuiButton: {
      root: {
        minWidth: 0,
      },
      contained: {
        boxShadow: 'none',
        '&:active': {
          boxShadow: 'none',
        },
        '&:focus': {
          boxShadow: 'none',
        },
      },
      containedSecondary: {
        color: '#fff',
        '&:hover': {
          backgroundColor: 'rgb(118, 195, 21)',
        },
      },
    },
    MuiButtonGroup: {
      root: {
        boxShadow: 'none',
      },
      contained: {
        boxShadow: 'none',
        '&:active': {
          boxShadow: 'none',
        },
        '&:focus': {
          boxShadow: 'none',
        },
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 40,
      },
    },
    MuiCardContent: {
      root: {
        '&:last-child': {
          paddingBottom: 16,
        },
      },
    },
    MuiLinearProgress: {
      root: {
        background: '#f3f3f3 !important',
      },
    },
    MuiDialogContent: {
      root: {
        overflow: 'hidden',
      },
    },
  },
  typography: {
    fontFamily: 'Inter, Roboto, Helvetica, Arial, sans-serif',
    h1: {
      fontSize: '2rem',
    },
    h2: {
      fontSize: '1.8rem',
    },
    h3: {
      fontSize: '1.6rem',
    },
    h4: {
      fontSize: '1.4rem',
    },
    h5: {
      fontSize: '1.2rem',
    },
    h6: {
      fontSize: '1rem',
    },
  },
};

rootTheme.overrides = { ...clipTheme.overrides, ...rootTheme.overrides };

export default rootTheme;
