import { apiSendData, POST, PUT, PATCH, DELETE, GET } from '../../helpers/request';
import { getActionTypeInfo, createSuccessResponse, createErrorResponse, isFunction } from '../../helpers/reduxUtils';
import { PRODUCTIVE_MODE } from '../../common/authorizationModeType';

function processMiddleware(action, store, actionInfo) {
  const {
    payload: {
      route,
      query,
      data,
      success: successAction,
      failure: failureAction,
      apiEndPoint,
      fullRoute,
      mode = PRODUCTIVE_MODE,
      headers = {},
    },
  } = action;

  function success(res) {
    store.dispatch(createSuccessResponse(actionInfo, res));
    if (isFunction(successAction)) {
      successAction(res, store);
    }
  }

  function failure(error) {
    store.dispatch(createErrorResponse(actionInfo, error));
    if (isFunction(failureAction)) {
      failureAction(error, store);
    }
    throw error;
  }
  const autoHandleError = !isFunction(failure);

  switch (actionInfo.verb) {
    case 'GET':
      apiSendData(GET, route, query, {}, mode, apiEndPoint, fullRoute, headers, autoHandleError, store)
        .then(success, failure)
        .catch(e => console.log(e));
      break;
    case 'PUT':
      apiSendData(PUT, route, {}, data, mode, apiEndPoint, fullRoute, headers, autoHandleError, store)
        .then(success, failure)
        .catch(e => console.log(e));
      break;
    case 'POST':
      apiSendData(POST, route, {}, data, mode, apiEndPoint, fullRoute, headers, autoHandleError, store)
        .then(success, failure)
        .catch(e => console.log(e));
      break;
    case 'PATCH':
      apiSendData(PATCH, route, {}, data, mode, apiEndPoint, fullRoute, headers, autoHandleError, store)
        .then(success, failure)
        .catch(e => console.log(e));
      break;
    case 'DELETE':
      apiSendData(DELETE, route, {}, data, mode, apiEndPoint, fullRoute, headers, autoHandleError, store)
        .then(success, failure)
        .catch(e => console.log(e));
      break;
    default:
      throw new Error('Bad format on the API method name');
  }
}

const apiResolveMiddleware = store => next => action => {
  const actionInfo = getActionTypeInfo(action.type);
  if (actionInfo.correctActionType === true) {
    processMiddleware(action, store, actionInfo);
  }
  next(action);
};

export default apiResolveMiddleware;
