import Cookies from 'js-cookie';
import getConfigValue from '../config/enviromentVariables';
import { PRODUCTIVE_MODE, SANDBOX_MODE } from '../common/authorizationModeType';
import { validateMode } from '../common/modeUtils';

const signInCookieDomain = getConfigValue('signInCookieDomain');
const PRODUCTION_AUTH_COOKIE_NAME = 'access_token';
const SANDBOX_AUTH_COOKIE_NAME = 'sandbox_access_token';

/**
 * Gets the domain name of a hostname
 * @param hostName - the hostname
 * @return string - The domain name
 */
function getDomainName(hostName) {
  return hostName.substring(hostName.lastIndexOf('.', hostName.lastIndexOf('.') - 1) + 1);
}

/*
 * Gets the cookie name depending on the environment
 * @param mode - The environment name
 * @return string - The cookie name
 */
function getCookieNameByMode(mode) {
  return mode === SANDBOX_MODE ? SANDBOX_AUTH_COOKIE_NAME : PRODUCTION_AUTH_COOKIE_NAME;
}

/**
 * Creates a name for a cookie depending on the environment
 * @param env - The environment name
 * @param baseName - The base name for the cookie
 * @return string - The cookie name
 */
function createCookieName(env, baseName) {
  if (env === 'production') return baseName;
  return `${getConfigValue('env')}_${baseName}`;
}

class CookieService {
  defaultOptions = () => ({ expires: 60, domain: signInCookieDomain });

  setItem = (key, value, options) => {
    const cookieOptions = options || this.defaultOptions();
    Cookies.set(key, value, cookieOptions);
  };

  removeItem = (key, options) => {
    const cookieOptions = options || this.defaultOptions();
    Cookies.remove(key, cookieOptions);
  };

  getItem = key => {
    return Cookies.get(key);
  };

  clearToken = () => {
    const domain = `${getDomainName(window.location.hostname)}`;
    const options = { expires: 60, domain };
    const cookieName = createCookieName(getConfigValue('env'), PRODUCTION_AUTH_COOKIE_NAME);
    const cookieNameSandbox = createCookieName(getConfigValue('env'), SANDBOX_AUTH_COOKIE_NAME);
    this.removeItem(cookieName, options);
    this.removeItem(cookieNameSandbox, options);
  };

  saveToken = (token, mode) => {
    validateMode(mode);
    const domain = `${getDomainName(window.location.hostname)}`;
    const options = { expires: 60, domain };
    const cookieName = createCookieName(getConfigValue('env'), getCookieNameByMode(mode));
    this.setItem(cookieName, token, options);
  };

  getToken = mode => {
    validateMode(mode);
    //This is used for always get token from environment variable without necessity of go through login process, Just in local.
    if (mode === PRODUCTIVE_MODE && getConfigValue('env') === 'local') {
      const token = getConfigValue('loginToken');
      //This is used for save productive token in cookie, as we aren't log users in local mode
      //therefore we can't save token in cookie
      this.saveToken(token, mode);
      return token;
    }

    const cookieName = createCookieName(getConfigValue('env'), getCookieNameByMode(mode));
    return this.getItem(cookieName);
  };
}

const instance = new CookieService();

export default instance;
