import produce from 'immer';

import { successResponse, requestState } from '../../../helpers/reduxUtils';
import {
  POSTBACK_GET_INFO,
  POSTBACK_UPDATE_WEBHOOK,
  POSTBACK_UPDATE_NOTIFICATION_STATUS,
} from '../../actions/webhooks';

export const initialState = {
  loading: null,
  destination: null,
  enabled: null,
};

const webhooksReducer = (state = initialState, action = {}) =>
  produce(state, draft => {
    const { type, payload } = action;
    switch (type) {
      case requestState('get', POSTBACK_GET_INFO): {
        draft.loading = true;
        break;
      }
      case successResponse('get', POSTBACK_GET_INFO): {
        draft.loading = null;
        draft.destination = payload.data?.notification_postback?.destination;
        draft.enabled = payload.data?.notification_postback?.enabled;
        break;
      }
      case successResponse('post', POSTBACK_UPDATE_WEBHOOK): {
        if (isEmpty(payload.data)) {
          draft.destination = true;
          draft.enabled = true;
          break;
        }
        draft.destination = payload.data?.notification?.postback?.destination;
        draft.enabled = payload.data?.notification?.postback?.enabled;
        break;
      }
      case successResponse('put', POSTBACK_UPDATE_NOTIFICATION_STATUS): {
        draft.destination = payload.data.endpoint;
        draft.enabled = payload.data.enabled;
        break;
      }
      default:
        break;
    }
  });

const isEmpty = obj => {
  return Object.keys(obj).length === 0;
};

export default webhooksReducer;
