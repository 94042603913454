import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import './Skeleton.css';

const Skeleton = ({ length, className, height, ...otherProps }) => {
  const styleProps = {
    ...(height && {
      height: height,
    }),
  };
  return (
    <div className={clsx('wrap-skeleton', className)} {...otherProps}>
      {Array.from({ length }).map((_, index) => (
        <div key={`skeleton-${index}`} className="skeleton" style={styleProps} />
      ))}
    </div>
  );
};

Skeleton.defaultProps = {
  className: '',
  length: 3,
  height: '',
};

Skeleton.propTypes = {
  className: PropTypes.string,
  length: PropTypes.number,
  height: PropTypes.string,
};

export default Skeleton;
