/* eslint max-len: 0 */
import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { ClipTypography } from '@clipmx/clip-storybook/dist/components/Typography/Typography';
import { Checkbox, FormControlLabel, TextField } from '@material-ui/core';
import Icons from '../Icons';
import { useDetailCredential } from './useDetailCredential';
import './DetailCredential.css';
import { toDateLong } from '../../../helpers/dateHelpers';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { deleteApiApplication } from '../../../redux/actions/merchants';

const DetailCredential = ({
  className,
  apiKeyCredential = '',
  apiSecretCredential = '',
  nameCredential = '',
  createdAt = '',
  typeCredential = 'prod' /* or test */,
  deleteApiApplication: deleteApiKey,
}) => {
  const {
    props: { isShowKeySecret },
    methods: { showKeySecret, deleteCredential },
    microcomponents: { Legend, IconCopy },
  } = useDetailCredential({ apiSecretCredential, apiKeyCredential, deleteApiKey, nameCredential, typeCredential });
  return (
    <div
      className={clsx('wrap-detail-credential', className && className)}
      data-testid={`detail-credential-${typeCredential}`}
    >
      <ClipTypography
        className="title"
        fontWeight="semiBold"
        variant="h2"
        component="h2"
        gutterBottom
        data-testid="name-credential"
      >
        {nameCredential}
        {typeCredential && (
          <span className={clsx(`label-${typeCredential}`)} data-testid="label-type-credential">
            {typeCredential === 'prod' ? 'Producción' : 'Pruebas'}
          </span>
        )}
        <Icons
          type="custom"
          nameCustomIcon="delete"
          className="icon-delete"
          onClick={deleteCredential}
          data-testid="btn-delete-detail-credential"
        />
      </ClipTypography>
      {createdAt && (
        <ClipTypography variant="body1" align="justify" fontWeight="regular" data-testid="date-create-credential">
          Creada el {toDateLong(createdAt)}
        </ClipTypography>
      )}
      <div className="region-form">
        <TextField
          className="input key-api"
          label="Clave API"
          variant="outlined"
          value={apiKeyCredential}
          InputProps={{
            endAdornment: <IconCopy type="apiKey" data={apiKeyCredential} />,
          }}
          inputProps={{
            'data-testid': 'input-key-credential',
          }}
        />
        {apiSecretCredential && (
          <>
            <TextField
              className="input key-secret"
              label="Clave secreta"
              variant="outlined"
              value={apiSecretCredential}
              type={isShowKeySecret ? 'text' : 'password'}
              size={13000}
              helperText="Una vez creada la credencial, no podrás volver a consultar la clave secreta. Guárdala en un lugar seguro."
              InputProps={{
                endAdornment: <IconCopy type="secretKey" data={apiSecretCredential} />,
              }}
              inputProps={{
                'data-testid': 'input-secret-credential',
                style: {
                  fontSize: isShowKeySecret ? '16px' : '30px',
                  maxHeight: isShowKeySecret ? 'auto' : '19px',
                },
              }}
            />
            <FormControlLabel
              className="container-checkbox"
              control={
                <Checkbox
                  checked={isShowKeySecret}
                  onChange={showKeySecret}
                  name="checkedB"
                  color="primary"
                  data-testid="check-show-secret-credential"
                  inputProps={{
                    'data-testid': 'input-check-show-secret-credential',
                  }}
                />
              }
              label="Mostrar clave secreta"
            />
          </>
        )}
      </div>
      <div className={clsx('message', apiSecretCredential === '' && 'warning')} data-testid="container-message">
        <Icons type="icon" iconName="info" className="icon-info" />
        {Legend}
      </div>
    </div>
  );
};

DetailCredential.defaultProps = {
  className: '',
};

DetailCredential.propTypes = {
  className: PropTypes.string,
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ deleteApiApplication }, dispatch);
}

export default connect(null, mapDispatchToProps)(DetailCredential);
