import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@material-ui/core/Icon';
import clsx from 'clsx';
import panelIcon from './../../files/panel.svg';
import webhookIcon from './../../files/webhook.svg';
import codeIcon from './../../files/code.svg';
import testIcon from './../../files/test.svg';
import keyIcon from './../../files/key.svg';
import deleteIcon from './../../files/delete.svg';

const Icons = props => {
  const { type, iconName, className, nameCustomIcon, ...rest } = props;
  switch (type) {
    case 'icon':
      return (
        <Icon data-testid="materialIcon" className={clsx('item-icon text-middle', className)} {...rest}>
          {iconName}
        </Icon>
      );
    case 'iconText':
      return (
        <span data-testid="textIcon" {...rest} className={clsx(className)}>
          {iconName}
        </span>
      );
    case 'custom':
      switch (nameCustomIcon) {
        case 'webhook':
          return <img alt="webhook-icon" src={webhookIcon} {...rest} className={clsx(className)} />;
        case 'code':
          return <img alt="code-icon" src={codeIcon} {...rest} className={clsx(className)} />;
        case 'test':
          return <img alt="code-icon" src={testIcon} {...rest} className={clsx(className)} />;
        case 'key':
          return <img alt="code-icon" src={keyIcon} {...rest} className={clsx(className)} />;
        case 'delete':
          return <img alt="code-icon" src={deleteIcon} {...rest} className={clsx(className)} />;
        default:
          return <img alt="panel-icon" src={panelIcon} {...rest} className={clsx(className)} />;
      }
    default:
      return <i data-testid="clipIcon" className={clsx(`clip-icon-${iconName}`, className)} {...rest} />;
  }
};

Icons.defaultProps = {
  iconName: '',
  type: 'icon',
  className: '',
  nameCustomIcon: 'panel',
};

Icons.propTypes = {
  iconName: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.oneOf(['icon', 'iconText', 'clipIcon', 'custom']),
  nameCustomIcon: PropTypes.oneOf(['panel', 'webhook', 'code', 'test', 'key', 'delete']),
};

export default Icons;
