import makeStyles from '@clipmx/clip-storybook/dist/theme/clipMakeStyles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: '700px',
    borderRadius: 6,
    boxShadow: '0 0 8px 0 rgba(128, 139, 145, 0.27)',
  },
  title: {
    fontWeight: '600',
  },
  toggleContainer: {
    display: 'flex',
    marginTop: '16px',
    alignItems: 'center',
  },
  toggleDescription: {
    fontWeight: 'bold',
  },
  webHookTestContainer: {
    marginTop: '24px',
    paddingTop: '16px',
    borderTop: `1px solid ${theme.palette.grey[300]}`,
  },
  webHookTestAction: {
    marginTop: '8px',
  },
  webhookLink: {
    color: theme.palette.celurean.base,
    textDecoration: 'none',
    fontWeight: '500',
  },
}));

export default useStyles;
