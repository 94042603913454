import React from 'react';
import { ClipTypography } from '@clipmx/clip-storybook/dist/components/Typography/Typography';
import CircularProgress from '@clipmx/clip-storybook/dist/components/CircularProgress';
import './Loading.css';

const Loading = () => {
  return (
    <div className="wrap-loading">
      <CircularProgress color="primary" size={35} className="circular-progress" />
      <ClipTypography variant="body1" align="justify" className="legend">
        Cargando...
      </ClipTypography>
    </div>
  );
};

export default Loading;
