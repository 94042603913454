/* eslint max-len: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import DetailCredential from '../../common/DetailCredential';
import { ClipButton } from '@clipmx/clip-storybook/dist/components/Button/Button';
import Icons from '../../common/Icons';
import './DetailCredentialView.css';
import { useDetailCredentialView } from './useDetailCredentialView';

const DetailCredentialView = () => {
  const {
    props: { isMobil, apiKeyCredential, apiSecretCredential = '', nameCredential, createdAt, typeCredential },
    methods: { BackToList },
  } = useDetailCredentialView();
  return (
    <div className="wrap-detail-credential-view" data-testid="wrap-detail-credential-view">
      <ClipButton
        className="btn-back"
        startIcon={<Icons type="icon" iconName={isMobil ? 'keyboard_arrow_left_rounded' : 'arrow_back'} />}
        onClick={() => BackToList(apiSecretCredential !== '')}
        data-testid="cta-back"
      >
        {!isMobil && 'Regresar'}
      </ClipButton>
      <div className="region-detail">
        <DetailCredential
          apiKeyCredential={apiKeyCredential}
          apiSecretCredential={apiSecretCredential}
          nameCredential={nameCredential}
          createdAt={createdAt}
          typeCredential={typeCredential}
        />
      </div>
    </div>
  );
};

DetailCredentialView.defaultProps = {
  className: '',
};

DetailCredentialView.propTypes = {
  className: PropTypes.string,
};

export default DetailCredentialView;
