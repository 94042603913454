import React, { memo, useState, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Field } from 'react-final-form';

import Grid from '@clipmx/clip-storybook/dist/components/Grid';
import ClipButton from '@clipmx/clip-storybook/dist/components/Button';
import { CreateOutlined as CreateIcon } from '@clipmx/clip-storybook/dist/components/Icons';

import InputLabeled from '../../formFields/InputLabeled';
import { AlertContext } from '../../Alert';

import { isURL } from './webhookUtils';
import { updatePostbackWebHook } from '../../../../redux/actions/webhooks';
import useStyles from './useStyles';

import { EVENTS, CleverTap } from '../../../../events';

const WebHookForm = memo(() => {
  const { openAlert } = useContext(AlertContext);
  const dispatch = useDispatch();
  const webhookUrl = useSelector(state => state?.webhooks?.destination);
  let endpoint;

  const isDisabled = () => !!webhookUrl;

  const [disabled, setDisabled] = useState(!!isDisabled());

  const onSubmit = values => {
    endpoint = values?.endpoint;
    dispatch(updatePostbackWebHook(endpoint, onSuccess, onFailure));
  };

  const onSuccess = () => {
    setDisabled(true);
    openAlert({ severity: 'success', message: 'Tus ajustes se han guardado con éxito.' });
    CleverTap.event(EVENTS.WEBHOOKS.SETUP_URL_SUCCEEDED, {
      URL: endpoint,
    });
  };

  const onFailure = () => {
    openAlert({ severity: 'error', message: 'Se produjo un error. Intenta de nuevo.' });
    CleverTap.event(EVENTS.WEBHOOKS.SETUP_URL_FAILED);
  };

  const validateURL = value => {
    return isURL(value) && 'Introduce una URL válida';
  };

  const onClickEdit = () => {
    setDisabled(!disabled);
  };

  const classes = useStyles();

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, submitting, invalid }) => (
        <form onSubmit={handleSubmit} data-testid="webhook-form">
          <Grid container spacing={2}>
            <Grid item xs={12} className={classes.formContainer}>
              <Field
                className={classes.inputContainer}
                data-testid="webhook-form-input"
                name="endpoint"
                placeholder="URL del endpoint"
                label="URL del endpoint"
                validate={validateURL}
                initialValue={webhookUrl}
                disabled={disabled}
                component={InputLabeled}
                adornmentIcon={!!webhookUrl && <CreateIcon data-testid="webhook-edit-icon" onClick={onClickEdit} />}
              />
            </Grid>
            <Grid className={classes.actions} item xs={12}>
              <ClipButton
                disabled={invalid || submitting || disabled}
                type="submit"
                variant="contained"
                color="secondary"
                data-testid="webhook-submit-button"
              >
                Guardar
              </ClipButton>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
});

export default WebHookForm;
