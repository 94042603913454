import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import RouteWithLayout from './RouteWithLayout';

import DashboardLayout from '../components/layouts/DashboardLayout';
import ApiCredentials from '../components/views/ApiCredentials';
import AppConfiguration from '../components/views/AppConfiguration';
import WebHooks from '../components/views/WebHooks';
import DetailCredentialView from '../components/views/DetailCredentialView';

export const AppRouterComponent = BrowserRouter;

const Router = props => {
  return (
    <div data-testid="router">
      <Switch>
        <Route exact path="/">
          <Redirect to="/credentials"></Redirect>
        </Route>
        <RouteWithLayout
          path="/documentacion"
          exact
          component={AppConfiguration}
          layout={DashboardLayout}
          prevProps={{ ...props }}
        />
        <RouteWithLayout
          path="/credentials"
          exact
          component={ApiCredentials}
          layout={DashboardLayout}
          prevProps={{ ...props }}
        />
        <RouteWithLayout
          path="/credentials/:apiKeyId"
          exact
          component={DetailCredentialView}
          layout={DashboardLayout}
          prevProps={{ ...props }}
        />
        <RouteWithLayout
          path="/webhooks"
          exact
          component={WebHooks}
          layout={DashboardLayout}
          prevProps={{ ...props }}
        />
        {/* <RouteWithLayout
          path="/sdk/ios"
          exact
          component={SDKInformation}
          layout={DashboardLayout}
          prevProps={{ ...props }}
        />
        <RouteWithLayout
          path="/sdk/demo-project-android"
          exact
          component={SDKInformation}
          layout={DashboardLayout}
          prevProps={{ ...props }}
        />
        <RouteWithLayout
          path="/sdk/demo-project-ios"
          exact
          component={SDKInformation}
          layout={DashboardLayout}
          prevProps={{ ...props }}
        /> */}
        <Redirect to="/credentials" />
      </Switch>
    </div>
  );
};
export default Router;
